import i18n from "i18next";
import Backend from "i18next-http-backend";
//import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    ns: ["common", "help", "visit"],
    debug: true,
    lng: "en",
    load: "all",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        cache: "no-store",
      },
    },
    // react-i18next options
    react: {
      useSuspense: true,
      wait: true,
    },
  });

// load additional namespaces after initialization
i18n.loadNamespaces(
  [
    "/common",
    "/help",
    "/visit",
    "/Dialogs/snapshot",
    "/Administration/Questionnaire/Edit/Nodes/Dialog/info-node",
    "/Administration/Questionnaire/Edit/Nodes/Dialog/question-node",
    "/Administration/Questionnaire/Edit/Nodes/Dialog/add-message",
    "/Administration/Questionnaire/Edit/Nodes/Dialog/condition-node",
    "/Patient/PatientFile/PatientEventTimeLine/Dialog/Report/report",
  ],
  (err, t) => {}
);

export default i18n;
