import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userReducer(state = initialState.layout, action) {
  switch (action.type) {
    case types.LAYOUT_SET_SIDEBAR:
      //TODO set only isSidebarOpened from layout object
      const actionSideBar =
        action && action.layout ? action.layout.isSidebarOpened : null;
      return {
        ...state,
        isSidebarOpened:
          actionSideBar !== null ? actionSideBar : !state.isSidebarOpened,
      };
    default:
      return state;
  }
}
