import { handleResponse, handleError } from "./apiUtils";
import { getIdpAccessToken } from "../sagas/idpSaga";
const baseUrl = process.env.REACT_APP_API_URL;

function getRealAccessToken() {
  const accessToken = getIdpAccessToken();
  if (accessToken) return accessToken;
}

export function getHumanBody() {
  const internalProvidersUrl = baseUrl + "/api/v1/human-body";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.humanBodys,
        page: 1,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    });
}

export function getHumanBodyByParentId(parentId) {
  const routeParam = parentId ? "/" + parentId : "";
  const internalProvidersUrl =
    baseUrl + "/api/v1/human-body/by-parent-id" + routeParam;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
