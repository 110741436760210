import { handleResponse, handleError } from "./apiUtils";
import { getIdpAccessToken } from "../sagas/idpSaga";
const baseUrl = process.env.REACT_APP_API_URL;

function getRealAccessToken() {
  const accessToken = getIdpAccessToken();
  if (accessToken) return accessToken;
}

export function postChatbotItemInformation(chatbotInformationItem) {
  const internalProvidersUrl = baseUrl + "/api/v1/chatbot-information";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(chatbotInformationItem, null, 2),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function translateChatbotItem(id, chatbotInformationItem) {
  const internalProvidersUrl =
    baseUrl + "/api/v1/chatbot-information/translate/" + id;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(chatbotInformationItem, null, 2),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getQuestions(query) {
  const internalProvidersUrl = baseUrl + "/api/v1/chatbot-information";
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    passQuery.type = 1;
    url.search = new URLSearchParams(passQuery).toString();
  } else {
    const passQuery = { type: 1 };
    url.search = new URLSearchParams(passQuery).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAnswers(query) {
  const internalProvidersUrl = baseUrl + "/api/v1/chatbot-information";
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    passQuery.type = 2;
    url.search = new URLSearchParams(passQuery).toString();
  } else {
    const passQuery = { type: 2 };
    url.search = new URLSearchParams(passQuery).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getHints(query) {
  const internalProvidersUrl = baseUrl + "/api/v1/chatbot-information";
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    passQuery.type = 4;
    url.search = new URLSearchParams(passQuery).toString();
  } else {
    const passQuery = { type: 4 };
    url.search = new URLSearchParams(passQuery).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getInformations(query) {
  const internalProvidersUrl = baseUrl + "/api/v1/chatbot-information";
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    passQuery.type = 0;
    url.search = new URLSearchParams(passQuery).toString();
  } else {
    const passQuery = { type: 0 };
    url.search = new URLSearchParams(passQuery).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getChatbotInformationItem(id, query) {
  const internalProvidersUrl = baseUrl + "/api/v1/chatbot-information/" + id;
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    url.search = new URLSearchParams(passQuery).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
