import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function webRtcReducer(state = initialState.webRtc, action) {
  switch (action.type) {
    case types.SET_WEBRTC_ISCONNECTED:
      return { ...state, isConnected: action.isConnected };
    case types.SET_WEBRTC_GET_CLIENTS:
      const allClients = addClients(state.clients, action.clients);
      return { ...state, clients: allClients };
    //return { ...state, clients: addClients(state.clients,action.clients) };
    case types.SET_WEBRTC_GET_CLIENT:
      const prevClients = addClient(state.clients, action.client);
      return { ...state, clients: prevClients };
    case types.SET_WEBRTC_REMOVE_CLIENT:
      const clients = removeClient(state.clients, action.client);
      return { ...state, clients: clients };
    default:
      return state;
  }
}

const addClients = (clients, newClients) => {
  const prevClients = [...clients];
  newClients.forEach((client) => {
    const index = prevClients.findIndex(
      (prev) => prev.connectionId === client.connectionId
    );
    if (index <= -1) {
      prevClients.push(client);
    }
  });
  return prevClients;
};

const addClient = (clients, client) => {
  const prevClients = [...clients];
  const index = prevClients.findIndex(
    (prev) => prev.connectionId === client.connectionId
  );
  if (index <= -1) {
    prevClients.push(client);
  }
  return prevClients;
};

const removeClient = (clients, client) => {
  const prevClients = [...clients];
  const itemIndex = prevClients.findIndex(
    (prev) => prev.connectionId === client.connectionId
  );
  if (itemIndex > -1) {
    prevClients.splice(itemIndex, 1);
  }
  return prevClients;
};
