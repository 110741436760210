import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.AUTH_RESULT:
      return { ...state, status: action.status };
    case types.AUTH_RENEWTOKEN_RESULT:
      return { ...state, renewTokenStatus: action.renewTokenStatus };
    case types.AUTH_SET_PROFILE:
      return { ...state, profile: action.profile };

    default:
      return state;
  }
}
