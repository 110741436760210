import * as types from "./actionTypes";
import {
  getDrugDoseUnit,
  getDrugForm,
  getDrugType,
} from "../../api/lookupDataApi";

export function getDrugDoseUnitDispatch() {
  return function (dispatch) {
    getDrugDoseUnit()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_DRUG_DOSE_UNIT,
          lookup: { doseUnits: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getDrugFormDispatch() {
  return function (dispatch) {
    getDrugForm()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_DRUG_FORM,
          lookup: { drugForms: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getDrugTypeDispatch() {
  return function (dispatch) {
    getDrugType()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_DRUG_TYPE,
          lookup: { drugTypes: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}
