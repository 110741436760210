import { handleResponse, handleError } from "./apiUtils";
import { getIdpAccessToken } from "../sagas/idpSaga";
const baseUrl = process.env.REACT_APP_API_URL;

function getRealAccessToken() {
  const accessToken = getIdpAccessToken();
  if (accessToken) return accessToken;
}

export function postActivity(activity) {
  const internalProvidersUrl = baseUrl + "/api/v1/activity-service";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(activity, null, 2),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putActivity(activity) {
  const internalProvidersUrl = baseUrl + "/api/v1/activity-service";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(activity, null, 2),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteActivity(id) {
  const internalProvidersUrl = baseUrl + "/api/v1/activity-service/" + id;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllActivity() {
  const internalProvidersUrl = baseUrl + "/api/v1/activity-service/all";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllActivities() {
  const internalProvidersUrl = baseUrl + "/api/v1/activity-service/all";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        page: 1,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    });
}

export function getActivityTableList(query) {
  const internalProvidersUrl = baseUrl + "/api/v1/activity-service";
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    const filterQuery = JSON.stringify(passQuery.filters);
    passQuery.page += 1;
    passQuery.filters = filterQuery;
    url.search = new URLSearchParams(passQuery).toString();
  } else {
    url.search = new URLSearchParams({ page: -1 }).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.activitys,
        page: query ? query.page : 0,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    });
}

export function getActivityByType(type, query) {
  const internalProvidersUrl =
    baseUrl + "/api/v1/activity-service/by-type/" + type;
  var url = new URL(internalProvidersUrl);
  if (query) {
    let passQuery = { ...query };
    const filterQuery = JSON.stringify(passQuery.filters);
    passQuery.page += 1;
    passQuery.filters = filterQuery;
    url.search = new URLSearchParams(passQuery).toString();
  } else {
    url.search = new URLSearchParams({ page: 1 }).toString();
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.records,
        page: query ? query.page : 0,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    });
}

export function getActivityMetadata(id) {
  const internalProvidersUrl =
    baseUrl + `/api/v1/activity-service/${id}/metadata`;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putActivityMetadata(id, metadata) {
  const internalProvidersUrl =
    baseUrl + `/api/v1/activity-service/${id}/metadata`;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(metadata, null, 2),
  })
    .then(handleResponse)
    .catch(handleError);
}
