import { createSelector } from "reselect";

const app = (state) => state.app;

const getApp = (app) => {
  const screenPosition = app.screenPosition;
  const lang = app.lang;
  const theme = app.theme;
  return { screenPosition, lang, theme };
};

export const appSelector = createSelector(app, getApp);
