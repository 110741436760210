import { take, put, call } from "redux-saga/effects";
import * as types from "../redux/actions/actionTypes";
import i18n from "../i18n";
import Moment from "react-moment";

function getScreenPosition(lang) {
  if (lang === "he") return "rtl";
  return "ltr";
}

export function* languageSaga() {
  while (true) {
    const { lang } = yield take(types.SAGA_CHANGE_LANGUAGE);
    yield put({ type: types.APP_SET_LANGUAGE, lang });
    const screenPosition = yield call(getScreenPosition, lang);
    yield put({ type: types.APP_SET_SCREEN_POSITION, screenPosition });
    i18n.changeLanguage(lang);
    Moment.globalMoment.lang(lang);
    Moment.globalLocale = lang;
    document.body.setAttribute("dir", screenPosition);
  }
}
